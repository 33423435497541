import ReactModal from "react-modal";
import styles from "./Modal.css";
import cn from "classnames";

export const Modal = ({
  title,
  show,
  actions,
  large = false,
  children,
  ...props
}) => {
  return (
    <ReactModal
      {...props}
      isOpen={show}
      className={cn(styles.modal, large && styles.large)}
      overlayClassName={styles.overlay}
    >
      {!!title && <h2 className={styles.title}>{title}</h2>}
      {!!children && <div className={styles.content}>{children}</div>}
      {!!actions && <div className={styles.actions}>{actions}</div>}
    </ReactModal>
  );
};
