import { Modal } from "./Modal";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { useRef, useState } from "react";

export const SavingModal = ({
  show,
  saving,
  configId,
  onClose,
  onConfirmExisting,
  onDenyExisting
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const [copied, setCopied] = useState(false);

  function handleSelect() {
    inputRef.current.focus();
    inputRef.current.select();
    inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    document.execCommand("copy");
    setCopied(true);
  }

  return (
    <Modal
      title={
        saving ? t("saving-dialog_title") : t("saving-dialog_exising-title")
      }
      show={show}
      actions={
        saving ? (
          <>
            <Button secondary expanded onClick={onClose}>
              {t("saving-dialog_close")}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onConfirmExisting}>
              {t("saving-dialog_confirm-existing")}
            </Button>{" "}
            <Button secondary onClick={onDenyExisting}>
              {t("saving-dialog_deny-existing")}
            </Button>
          </>
        )
      }
    >
      {saving ? (
        !configId ? (
          t("saving-dialog_saving")
        ) : (
          <>
            <input
              ref={inputRef}
              type="text"
              readOnly
              onFocus={handleSelect}
              value={`${window.location.protocol}//${window.location.host}?id=${configId}`}
            />

            <Button expanded size="small" onClick={handleSelect}>
              {copied
                ? t("saving-dialog_copied-url")
                : t("saving-dialog_copy-url")}
            </Button>
          </>
        )
      ) : (
        <p style={{ textAlign: "center" }}>{t("saving-dialog_exising-copy")}</p>
      )}
    </Modal>
  );
};
