export const RatioContainer = ({ ratio = 1, children, ...props }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: 0,
        paddingBottom: `${100 * ratio}%`
      }}
      {...props}
    >
      <div style={{ position: "absolute", width: "100%" }}>{children}</div>
    </div>
  );
};
