import Fuse from "fuse.js";
import debounce from "lodash/debounce";
import partition from "lodash/partition";
import uniq from "lodash/uniq";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FILES } from "../constants/files";
import { Button } from "./Button";
import styles from "./FileSystem.css";
import { Accordion } from "./style/Accordion";
import { Tooltip, ButtonBase } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const iconList = (files: any[]) =>
  files.reduce<string[]>(
    (agg, file) =>
      typeof file === "string"
        ? [...agg, file]
        : [...agg, ...iconList(file.items)],
    []
  );

const ALL_FILES = uniq<string>(iconList(FILES));

const FUSE_OPTIONS = {
  id: "id",
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["translation"]
};

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "1rem",
    textAlign: "center",
    padding: ".5rem",
    overflow: "hidden",
    overscrollBehavior: "auto",
    lineHeight: 1.5
  },
  tooltipPlacementBottom: {
    marginTop: "-0.5rem"
  }
})(Tooltip);

export const FileSystem = React.memo<any>(
  ({ onSelect, onSearch, onCategoryOpen }) => {
    const { t, i18n } = useTranslation();
    const fuseRef = useRef<
      Fuse<{ id: string; translation: string }[], typeof FUSE_OPTIONS>
    >();
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchResults, setSearchResults] = useState<string[]>([]);
    const [displayAll, setDisplayAll] = useState(false);

    useEffect(() => {
      const translatedIconList = (files: string[], t) =>
        files.reduce(
          (agg, file) => [...agg, { id: file, translation: t(`icon_${file}`) }],
          []
        );
      fuseRef.current = new Fuse(
        translatedIconList(ALL_FILES, t),
        FUSE_OPTIONS
      );
    }, [i18n.language, t]);

    const search = debounce((query: string) => {
      setDisplayAll(false);
      setSearchQuery(query);
      onSearch(query);
    }, 200);

    useEffect(() => {
      const results = fuseRef.current.search(searchQuery);
      setSearchResults(uniq((results as unknown) as string[]));
    }, [searchQuery, i18n.language]);

    function mapItems(item) {
      if (typeof item === "string") {
        return (
          <CustomTooltip
            key={item}
            interactive
            PopperProps={{ disablePortal: true }}
            title={
              <>
                {t(`icon_${item}`)}
                <Button
                  outlined
                  expanded
                  tertiary
                  size="tiny"
                  onClick={() => onSelect(item)}
                >
                  {t("filesystem_add-to-map")}
                </Button>
              </>
            }
          >
            <ButtonBase
              className={styles.item}
              draggable
              onDragStart={e => {
                e.dataTransfer.setData("map/icon-id", item);
                e.dataTransfer.dropEffect = "copy";
                const image = e.currentTarget.querySelector("img");
                if (!image) return;

                try {
                  const canvas = document.createElement("canvas");
                  document.querySelector(".temporary-drag-image") &&
                    document.querySelector(".temporary-drag-image").remove();
                  canvas.width = image.width;
                  canvas.height = image.height;
                  canvas.style.position = "absolute";
                  canvas.style.top = "-9999px";
                  canvas.className = "temporary-drag-image";
                  canvas
                    .getContext("2d")
                    .drawImage(image, 0, 0, image.width, image.height);
                  document.body.append(canvas);
                  e.dataTransfer.setDragImage(
                    canvas,
                    image.width / 2,
                    image.height
                  );
                } catch (e) {}
              }}
            >
              <img
                className={styles.itemImage}
                id={`source_${item}`}
                src={`/static/icons/${item}.svg`}
                alt={t(`icon_${item}`)}
                draggable={false}
              />
            </ButtonBase>
          </CustomTooltip>
        );
      } else {
        const { label, items, open } = item;

        const [categories, icons] = partition(
          items,
          item => typeof item === "object"
        );

        return (
          <Accordion
            open={open}
            key={label}
            title={t(`icon_category--${label}`)}
            onToggle={open => onCategoryOpen(label, open)}
            independent
          >
            <div>
              {!!icons.length && (
                <div className={styles.category__content}>
                  {icons.map(mapItems)}
                </div>
              )}
              {categories.map(mapItems)}
            </div>
          </Accordion>
        );
      }
    }

    return (
      <div>
        <div className={styles.searchMask}>
          <input
            type="search"
            placeholder={t(`filesystem_search-placeholder`)}
            onChange={e => {
              search(e.currentTarget.value);
            }}
          />
          <Button
            className={styles.searchButton}
            secondary
            onClick={() => setDisplayAll(!displayAll)}
          >
            {displayAll
              ? t(`filesystem_search-categories`)
              : t(`filesystem_search-all`)}
          </Button>
        </div>

        {displayAll ? (
          <Accordion
            open={true}
            key="allResults"
            title={t(`filesystem_everything`)}
          >
            <div className={styles.category__content}>
              {ALL_FILES.map(mapItems)}
            </div>
          </Accordion>
        ) : searchResults.length ? (
          <Accordion
            open={true}
            key="searchResults"
            title={t(`filesystem_search-results`)}
          >
            <div className={styles.category__content}>
              {searchResults.map(mapItems)}
            </div>
          </Accordion>
        ) : (
          FILES.map(mapItems)
        )}
      </div>
    );
  }
);
