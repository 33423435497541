import styles from "./PrefillSelector.css";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { Tooltip, FormControl, FormLabel } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { Tip } from "./Tip";

export const PREFILLS = ["FULL", "ESSENTIALS", "EMPTY"];

export const PrefillSelector = ({ selected, dirty, onChange }) => {
  const { t } = useTranslation();

  function onClick(prefill: string) {
    if (!dirty || confirm(t(`prefill_warning-confirm`))) {
      onChange(prefill);
    }
  }

  return (
    <FormControl fullWidth margin="normal">
      <FormLabel>{t("prefill_title")}</FormLabel>

      {dirty ? (
        <>
          <div className={styles.container}>
            <Button
              alert
              expanded
              size="small"
              onClick={() => {
                onClick("NONE");
              }}
            >
              {t(`prefill_reset`)}
            </Button>
          </div>
          <Tip>{t(`prefill_warning-existing`)}</Tip>
        </>
      ) : (
        <div className={styles.container}>
          <ToggleButtonGroup
            exclusive
            value={selected}
            onChange={(e, value) => onChange(value)}
            classes={{ root: styles.group, grouped: styles.groupItem }}
          >
            {PREFILLS.map((prefill) => (
              <ToggleButton
                key={prefill}
                classes={{ root: styles.item, selected: styles.itemSelected }}
                value={prefill}
              >
                <Tooltip
                  classes={{ tooltip: styles.tooltip }}
                  title={
                    <>
                      <div className={styles.tooltipTitle}>
                        {t(`prefill_title--${prefill.toLowerCase()}`)}
                      </div>
                      {t(`prefill_description--${prefill.toLowerCase()}`)}
                    </>
                  }
                >
                  <img
                    src={
                      require(`../assets/images/prefill-${prefill.toLowerCase()}.png`)
                        .default
                    }
                    alt={prefill}
                  />
                </Tooltip>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      )}
    </FormControl>
  );
};
