import styles from "./InfoBox.css";
import { useState, FunctionComponent } from "react";
import InfoIcon from "../assets/icons/info.svg";

interface Props {
  defaultOpen?: boolean;
}

export const InfoBox: FunctionComponent<Props> = ({
  defaultOpen,
  children,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className={styles.container}>
      {open ? (
        <div className={styles.content}>
          {children}
          <button className={styles.button} onClick={() => setOpen(false)}>
            ✕
          </button>
        </div>
      ) : (
        <button
          className={styles.button}
          onClick={() => setOpen(true)}
          title="Info"
        >
          <img className={styles.icon} src={InfoIcon} alt="Info" />
        </button>
      )}
    </div>
  );
};
