export const FILES = [
  {
    label: "essentials",
    icon: "streetlight1",
    open: true,
    items: [
      "bench",
      "buggy",
      "bus_stop",
      "crosswalk",
      "dumpster1",
      "dumpster2",
      "hatchback_red",
      "lights",
      "phonebooth",
      "police_car",
      "streetlight1",
      "streetlight2",
      "streetlight3",
      "tree1",
      "tree2",
      "tree3",
      "tree4",
      "tree5",
      "tree6",
      "tree7",
      "tree8",
    ],
  },
  {
    label: "urban",
    icon: "synagogue",
    items: [
      {
        label: "business",
        icon: "florist",
        items: [
          "bakery",
          "bank",
          "bikeshop",
          "bookstore",
          "butcher",
          "convenience_store",
          "driving_school",
          "florist",
          "gas_station",
          "grocery_store1",
          "grocery_store2",
          "hairdresser",
          "hardware",
          "icecream_parlor",
          "mall",
          "market_cart",
          "market_sign",
          "market_stall1",
          "market_stall2",
          "office",
          "pharmacy",
          "shop",
        ],
      },
      {
        label: "details",
        icon: "fountain",
        items: [
          "bench",
          "buggy",
          "dumpster1",
          "dumpster2",
          "fire_hydrant",
          "fountain",
          "mailbox",
          "phonebooth",
          "portacabin",
          "portapotty",
          "recycling_blue",
          "recycling_brown",
          "recycling_green",
          "recycling_white",
          "statue",
          "streetlight1",
          "streetlight2",
          "streetlight3",
        ],
      },
      {
        label: "leisure",
        icon: "restaurant2",
        items: [
          "bar",
          "cafe",
          "carousel",
          "cinema",
          "circus_tent",
          "fast-food1",
          "fast-food2",
          "fast-food3",
          "ferris_wheel",
          "goal",
          "gym",
          "hoop",
          "halfpipe",
          "hotel",
          "icecream_parlor",
          "net",
          "nightclub",
          "playground_slide",
          "playground_swing",
          "playground_teeter-totter",
          "playground",
          "restaurant1",
          "restaurant2",
          "restaurant3",
          "stadium",
          "umbrella",
        ],
      },
      {
        label: "public",
        icon: "school",
        items: [
          "cemetery",
          "chapel",
          "church",
          "college",
          "community_center",
          "daycare_1",
          "daycare",
          "dentist",
          "doctor",
          "fire_station",
          "hospital1",
          "hospital2",
          "indoor_pool",
          "library",
          "mosque",
          "museum",
          "music_school",
          "outdoor_pool",
          "police_station",
          "post_office",
          "restrooms",
          "school",
          "synagogue",
          "townhall",
          "trainstation",
          "veterinary",
          "z_Grabstein1",
          "z_Grabstein2",
          "z_Grabstein3",
          "z_Grabstein4",
          "z_Grabstein5",
        ],
      },
      {
        label: "residential",
        icon: "house1",
        items: [
          "house1",
          "house2",
          "house3",
          "house4",
          "house5",
          "house6",
          "house7",
          "house8",
          "house9",
          "house10",
          "house11",
          "house12",
          "house13",
          "house14",
          "house15",
          "house16",
          "house17",
          "house18",
          "house19",
          "house20",
          "house21",
          "house22",
          "house23",
          "house24",
          "house25",
          "house26",
          "house27",
          "house28",
          "house29",
          "house30",
          "house31",
        ],
      },
    ],
  },
  {
    label: "rural",
    icon: "cow",
    items: [
      {
        label: "animals",
        icon: "",
        items: [
          "bear",
          "buck",
          "cat",
          "chicken",
          "cow",
          "dear",
          "dog",
          "dragon",
          "elefant",
          "elk",
          "fox",
          "geese",
          "giraffe",
          "goat",
          "hare1",
          "hare2",
          "hippo",
          "horse",
          "lion",
          "pig",
          "pigeon",
          "racoon",
          "rat",
          "sheep",
          "wolf",
          "tiger",
          "aardvark",
          "seagull1",
          "seagull2",
          "seagull3",
          "seagulls",
          "octopus",
          "whale",
          {
            label: "drawing_contest",
            icon: "",
            items: [
              "carla_robbe",
              "clara_nashorn",
              "connor_panda",
              "lennart_strauss",
              "leoni_jaguar",
              "maya_weisskopfseeadler",
            ],
          },
        ],
      },
      {
        label: "details",
        icon: "lookout",
        items: [
          "cabin",
          "fence",
          "lookout",
          "lagerfeuer",
          "windgenerator",
          "beet",
          "hochbeet",
          "schwimmtier",
          "schwimmring",
          "planschbecken",
        ],
      },
      {
        label: "farm",
        icon: "half-timbered_house1",
        items: [
          "barn1",
          "barn2",
          "farm",
          "fence",
          "greenhouse",
          "half-timbered_house1",
          "half-timbered_house2",
          "hay_bail1",
          "hay_bail2",
          "pumpkin_field",
          "silo1",
          "silo2",
          "tractor",
          "windmill",
        ],
      },
      {
        label: "nature",
        icon: "tree1",
        items: [
          "bush1",
          "bush2",
          "corn",
          "forrest",
          "lake",
          "logs",
          "pond",
          "tree1",
          "tree2",
          "tree3",
          "tree4",
          "tree5",
          "tree6",
          "tree7",
          "tree8",
          "wheat",
          "peartree",
          "appletree1",
          "appletree2",
          "bloomingtree1",
          "bloomingtree2",
          "bloomingtree3",
        ],
      },
    ],
  },
  {
    label: "transportation",
    icon: "car_purple",
    items: [
      {
        label: "airborne",
        icon: "airplane",
        items: [
          "airplane",
          "airport",
          "blimp",
          "control_tower",
          "hotair_balloon",
          "police_helicopter",
        ],
      },
      {
        label: "commercial",
        icon: "truck_red",
        items: [
          "truck_blue",
          "truck_green",
          "truck_long",
          "truck_red",
          "bullirot",
          "bulliblau",
        ],
      },
      {
        label: "construction_site",
        icon: "steamroller",
        items: [
          "bulldozer",
          "crane1",
          "crane2",
          "dumptruck",
          "excavator",
          "portacabin",
          "portapotty",
          "skidsteerloader",
          "steamroller",
          "tractor",
          "hole",
          "pile1",
          "pile2",
          "pile3",
          "barrier_open",
          "barrier_closed",
        ],
      },
      {
        label: "details",
        icon: "lights",
        items: [
          "bus_sign",
          "bus_stop",
          "crosswalk",
          "lights",
          "s-bahn_sign",
          "u-bahn_sign",
          "strassenbausatz1",
          "strassenbausatz2",
          "strassenbausatz3",
          "strassenbausatz4",
          "strassenbausatz5",
        ],
      },
      {
        label: "emergency_response",
        icon: "ambulance",
        items: [
          "ambulance",
          "emergency_doctor",
          "fire_engine",
          "ladder_truck",
          "police_boat",
          "police_car",
          "police_helicopter",
          "police_van",
        ],
      },
      {
        label: "farm",
        icon: "tractor",
        items: [
          "balingpress",
          "harvester_withwagon",
          "harvester",
          "lawnmower",
          "manurespreader",
          "manuretank",
          "plough",
          "tractor_wagon",
          "tractor",
          "wagon",
        ],
      },
      {
        label: "private",
        icon: "convertible",
        items: [
          "bike",
          "buggy",
          "camper_with_car",
          "camper",
          "car_purple",
          "car_yellow",
          "convertible",
          "hatchback_blue",
          "hatchback_green",
          "hatchback_red",
          "limo",
          "motercycle",
          "pick-up",
          "RV",
          "scooter",
        ],
      },
      {
        label: "public_transportation",
        icon: "bus",
        items: [
          "bus_sign",
          "bus_stop",
          "bus",
          "express_train",
          "lights",
          "s-bahn_sign",
          "s-bahn_stop",
          "subway",
          "train",
          "trainstation",
          "tram",
          "u-bahn_sign",
          "u-bahn_station",
        ],
      },
      {
        label: "waste_management",
        icon: "garbage_truck",
        items: [
          "dumpster1",
          "dumpster2",
          "garbage_truck",
          "recycling_blue",
          "recycling_brown",
          "recycling_green",
          "recycling_truck",
          "recycling_white",
          "street_sweeper",
        ],
      },
      {
        label: "water",
        icon: "boat",
        items: [
          "boat",
          "paper_boot",
          "pirate_ship",
          "police_boat",
          "raft",
          "swan_pedalboat",
          "kreuzfahrt",
          "containerschiff",
          "dampfer",
          "lighthouse",
          "strandkorb",
        ],
      },
    ],
  },
  {
    label: "people",
    icon: "boat",
    items: [
      "dogwalker1",
      "dogwalker2",
      "dogwalker3",
      "dogwalker4",
      "dogwalker5",
      "dogwalker6",
      "dogwalker7",
      "dogwalker8",
      "kid1",
      "kid2",
      "kid3",
      "kid4",
      "kid5",
      "kid6",
      "kid7",
      "kid8",
      "kid9",
      "kid10",
      "kid11",
      "kid12",
      "kid13",
      "kid14",
      "kid15",
      "kid16",
      "kid17",
      "kid18",
      "man1",
      "man2",
      "man3",
      "man4",
      "man5",
      "man6",
      "man7",
      "man8",
      "stroller1",
      "stroller2",
      "stroller3",
      "stroller4",
      "stroller5",
      "stroller6",
      "wheelchair1",
      "wheelchair2",
      "wheelchair3",
      "wheelchair4",
      "wheelchair5",
      "wheelchair6",
      "woman1",
      "woman2",
      "woman3",
      "woman4",
      "woman5",
      "woman6",
      "woman7",
      "woman8",
      "rosi",
      "fussballspieler",
      "Brautpaar1",
      "Brautpaar2",
      "Brautpaar3",
      "Brautpaar4",
      "Brautpaar5",
    ],
  },
  {
    label: "friends_and_family",
    icon: "friends_and_family",
    items: [
      {
        label: "houses",
        icon: "housemarker3",
        items: [
          "Hausmarker_l_1",
          "Hausmarker_l_2",
          "Hausmarker_l_3",
          "Hausmarker_l_4",
          "Hausmarker_l_5",
          "Hausmarker_l_6",
          "Hausmarker_l_7",
          "Hausmarker_l_8",
          "housemarker1",
          "housemarker2",
          "housemarker3",
          "housemarker4",
          "housemarker5",
          "housemarker6",
          "housemarker7",
          "housemarker8",
        ],
      },
      {
        label: "marker",
        icon: "marker_blue",
        items: [
          "marker_blue",
          "marker_green",
          "marker_lightblue",
          "marker_mint",
          "marker_orange",
          "marker_purple",
          "marker_red",
          "marker_yellow",
        ],
      },
      {
        label: "people",
        icon: "girl4",
        items: [
          "son1",
          "son2",
          "son3",
          "son4",
          "son5",
          "son6",
          "son7",
          "daughter1",
          "daughter2",
          "daughter3",
          "daughter4",
          "daughter5",
          "daughter6",
          "daughter7",
          "grandma1",
          "grandma2",
          "grandma3",
          "grandma4",
          "grandma5",
          "grandma6",
          "grandma7",
          "grandpa1",
          "grandpa2",
          "grandpa3",
          "grandpa4",
          "grandpa5",
          "grandpa6",
          "grandpa7",
          "dad1",
          "dad2",
          "dad3",
          "dad4",
          "dad5",
          "dad6",
          "dad7",
          "mom1",
          "mom2",
          "mom3",
          "mom4",
          "mom5",
          "mom6",
          "mom7",
        ],
      },
    ],
  },
  {
    label: "special",
    icon: "pirate_ship",
    items: [
      "carousel",
      "castle",
      "circus_tent",
      "ferris_wheel",
      "mineshaft",
      "monster",
      "pirate_ship",
      "protest",
      "UFO",
      "stern",
      "fire",
      "fire2",
      "Nessie",
      "ghost1",
      "ghost2",
      "ghost3",
    ],
  },
  {
    label: "seasonal",
    icon: "christmastree",
    items: ["christmastree", "sleigh", "snowman"],
  },
  {
    label: "bespoke",
    icon: "notredame",
    items: [
      "hermannplatz_hrrmnnbox",
      "hermannplatz_karstadt",
      "hermannplatz_tanzendes_paar",
      "beethoven_haus_bonn",
      "bulli_tks",
      "kinderhospiz_sonnenhof",
      "lobeblock",
      "stadtmuseumkassel",
      "schlossfriedrichsfelde",
      "notredame",
      "jaju",
      "watertower1",
      "watertower2",
      "watertower3",
      "Schleckerei_Schwarzmahler",
      "Alex",
      "hoelderlinturm_tuebingen",
      "st_pauli",
    ],
  },
  {
    label: "berlin",
    icon: "berlin_10",
    items: [
      "berlin_1",
      "berlin_2",
      "berlin_3",
      "berlin_4",
      "berlin_5",
      "berlin_6",
      "berlin_7",
      "berlin_8",
      "berlin_10",
      "berlin_11",
      "berlin_12",
      "berlin_13",
      "berlin_14",
    ],
  },
];
