import { expression } from "@mapbox/mapbox-gl-style-spec";
import cn from "classnames";
import { Feature, Point } from "geojson";
import { throttle } from "lodash";
import get from "lodash/get";
import { GeoJSONSource } from "mapbox-gl";
import Head from "next/head";
import React, {
  RefForwardingComponent,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import ReactMapGL, {
  PointerEvent,
  ViewportProps,
  ViewState,
} from "react-map-gl";
import uniqid from "uniqid";
import WebMercatorViewport from "viewport-mercator-project";
import { FormatId, FORMATS } from "../constants/formats";
import { STEPS } from "../constants/steps";
import { MapStyleContext } from "../contexts/map";
import {
  filterByPrefill,
  getStyleFeatureIdById,
  getStyleFeatures,
} from "../ducks/style";
import {
  loadMapImage,
  PREVIEW_IMAGE_WIDTH,
  useImageLoader,
} from "../helpers/loadImage";
import { BadgeOverlay } from "./BadgeOverlay";
import { DragOverlay } from "./DragOverlay";
import { IconControls } from "./IconControls";
import styles from "./Map.css";
import { NavigationControl } from "./map/NavigationControl";
import { activeIconReducer } from "../ducks/activeIcon";
import debounce from "lodash/debounce";

const MOVE_SPEED = 5;

export type MapState = typeof STEPS[number];

interface Props {
  state: MapState;
  format: FormatId;
  scale: number;
  viewport: ViewState;
  prefill?: string;
  render?: boolean;
  onViewportChange?: (viewport: ViewState | ViewportProps) => any;
  onRender?: (dataUri: string) => any;
  onFeatureAdded?: (feature: Feature<Point>) => any;
  onFeatureUpdated?: (feature: Feature<Point>) => any;
  onFeaturePlacement?: (features: Feature<Point>[]) => any;
  onFeatureReset?: () => any;
}

const MapComponent: RefForwardingComponent<any, Props> = (
  {
    state,
    format,
    scale,
    viewport,
    render,
    prefill = "FULL",
    onViewportChange,
    onRender,
    onFeatureAdded,
    onFeatureUpdated,
    onFeaturePlacement,
    onFeatureReset,
  },
  ref
) => {
  const containerRef = useRef<HTMLDivElement>();
  const mapRef = useRef<ReactMapGL>();

  const [interactable, setInteractable] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [baseStyle, dispatchStyle] = useContext(MapStyleContext);

  const [activeIcon, dispatchActiveIcon] = useReducer(activeIconReducer, null);

  const canManipulate = state === "DESIGN";
  const canNavigate = (!isMobile && state === "FORMAT") || state === "LOCATION";

  const style = useMemo(() => filterByPrefill(prefill, baseStyle), [
    prefill,
    baseStyle,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      render: () =>
        new Promise((resolve, reject) => {
          try {
            const map = mapRef.current.getMap();

            if (!map) return;

            const ratio = window.devicePixelRatio;
            //@ts-ignore
            window.devicePixelRatio =
              PREVIEW_IMAGE_WIDTH / FORMATS[format].width;
            map.resize();

            map.once("render", function() {
              map.getCanvas().toBlob(
                (data) => {
                  const url = URL.createObjectURL(data);
                  resolve(url);
                },
                "image/jpeg",
                1
              );
            });

            map.triggerRepaint();

            setTimeout(() => {
              //@ts-ignore
              window.devicePixelRatio = ratio;
              map.resize();
            });
          } catch (e) {
            reject(e);
          }
        }),
      dispatchActiveIcon,
    }),
    [mapRef, dispatchActiveIcon]
  );

  const syncActiveIcon = useCallback(
    debounce((id: string, feature: Feature<Point>) => {
      dispatchStyle({
        type: "SYNC_ACTIVE_ICON",
        id,
        feature,
      });

      onFeatureUpdated?.(feature);
    }, 250),
    [dispatchStyle, onFeatureUpdated]
  );

  const usedFormat =
    state === "LOCATION"
      ? FORMATS[isMobile ? "SMALL" : "MEDIUM"]
      : FORMATS[format];

  function onMove(e: PointerEvent) {
    if (!canManipulate) return;

    if (dragging) {
      dispatchActiveIcon({
        type: "POSITION",
        position: e.lngLat,
      });
    }
  }

  function onClick(e: PointerEvent) {
    if (!canManipulate) return;

    const map = mapRef.current.getMap();
    const features = map
      .queryRenderedFeatures(e.point, {
        layers: ["placedIcons"],
      })
      .sort((a, b) => (a.properties.priority > b.properties.priority ? 1 : -1));

    const feature = features.find((f) => f.layer.id === "placedIcons");

    if (feature) {
      const activeFeature: Feature<Point> = {
        type: "Feature",
        properties: feature.properties,
        geometry: feature.geometry as Point,
      };

      dispatchStyle({
        type: "SYNC_ACTIVE_ICON",
        id: activeFeature.properties.id,
        feature: activeFeature,
      });

      dispatchActiveIcon({
        type: "SET",
        feature: activeFeature,
      });
    } else {
      dispatchActiveIcon({
        type: "RESET",
      });
    }
  }

  function onDown(e: PointerEvent) {
    if (!canManipulate) return;

    const map = mapRef.current.getMap();
    const features = map.queryRenderedFeatures(e.point, {
      layers: ["placedIcons"],
    });

    const feature =
      !!activeIcon &&
      features.find((f) => f.properties.id === activeIcon.properties.id);

    if (feature) {
      dispatchActiveIcon({
        type: "OFFSET",
        coordinates: e.lngLat,
      });

      setDragging(true);
    }
  }

  function onUp(e: PointerEvent) {
    setDragging(false);
  }

  function onKeyPress(e: KeyboardEvent) {
    if (canManipulate && activeIcon) {
      // Arrow keys
      if (e.keyCode >= 37 && e.keyCode <= 40) {
        e.preventDefault();
        const projector = new WebMercatorViewport(viewport);
        const pixels = projector.project([
          activeIcon.geometry.coordinates[0],
          activeIcon.geometry.coordinates[1],
        ]);

        const speed = e.shiftKey ? MOVE_SPEED * 3 : MOVE_SPEED;

        const xOffset =
          e.keyCode === 37 ? -1 * speed : e.keyCode === 39 ? speed : 0;
        const yOffset =
          e.keyCode === 38 ? -1 * speed : e.keyCode === 40 ? speed : 0;

        dispatchActiveIcon({
          type: "POSITION",
          position: projector.unproject([
            pixels[0] + xOffset,
            pixels[1] + yOffset,
          ]),
          ignoreOffset: true,
        });
      }

      // Enter
      if (e.keyCode === 13) {
        e.preventDefault();
        dispatchActiveIcon({ type: "RESET" });
      }

      // Backspace
      if (e.keyCode === 8) {
        e.preventDefault();
        onDeleteFeature(activeIcon);
      }
    }
  }

  function onAddFeature(feature: Feature<Point>) {
    dispatchStyle({
      type: "ADD_POINT",
      feature,
    });

    dispatchActiveIcon({
      type: "SET",
      feature,
    });

    onFeatureAdded?.(feature);
  }

  function onDeleteFeature(feature: Feature<Point>) {
    dispatchStyle({
      type: "REMOVE_POINT",
      id: feature.properties.id,
    });

    dispatchActiveIcon({
      type: "RESET",
    });

    onFeatureUpdated?.(feature);
  }

  async function addIcon(icon: string, coordinates: [number, number]) {
    const map = mapRef.current.getMap();
    const id = uniqid();
    if (!icon) return;

    const feature: Feature<Point> = {
      id,
      type: "Feature",
      properties: {
        id,
        icon,
      },
      geometry: {
        type: "Point",
        coordinates,
      },
    };

    if (!map.hasImage(icon)) {
      await loadMapImage(map, icon);
    }

    onAddFeature(feature);
  }

  // Load missing images
  useImageLoader(mapRef.current);

  // Update draggedPoint source on dragging point
  useEffect(() => {
    if (!mapRef.current) return;

    const map = mapRef.current.getMap();

    if (!map) return;

    const source = map.getSource("activeIcon") as GeoJSONSource;

    if (!source) return;

    if (activeIcon) {
      source.setData(activeIcon);

      if (map.getLayoutProperty("activeIcon", "visibility") !== "visible") {
        map.setLayoutProperty("activeIcon", "visibility", "visible");
        map.removeFeatureState({ source: "placedIcons" });
        map.setFeatureState(
          {
            id: getStyleFeatureIdById(style, activeIcon.properties.id),
            source: "placedIcons",
          },
          { active: true }
        );
      }

      syncActiveIcon(activeIcon.properties.id, activeIcon);
    } else {
      source.setData({
        type: "Feature",
        properties: {},
        geometry: { type: "Point", coordinates: [0, 0] },
      });
      map.setLayoutProperty("activeIcon", "visibility", "none");
      map.removeFeatureState({ source: "placedIcons" });
    }
  }, [mapRef, activeIcon]);

  // Reset map graphics scaling
  useEffect(() => {
    const map = mapRef.current.getMap();

    if (!map) return;

    //@ts-ignore
    window.devicePixelRatio = scale * 2;

    map.resize();
  }, [mapRef, scale]);

  // On prefill change
  useEffect(() => {
    if (getStyleFeatures(style).length) {
      onFeatureReset?.();
    }

    dispatchActiveIcon({
      type: "RESET",
    });

    dispatchStyle({
      type: "RESET_PLACED_ICONS",
    });

    dispatchStyle({
      type: "SHOW_SOURCE_ICON_LAYERS",
    });
  }, [prefill]);

  /**
   * Move currently rendered features to placedIcons source on PLACING state change if it hasn't happened yet
   * @todo Move to imperative function
   */
  useEffect(() => {
    const map = mapRef.current.getMap();

    if (!map) return;

    dispatchActiveIcon({
      type: "RESET",
    });

    if (state !== "DESIGN" || getStyleFeatures(style).length) return;

    const features = map
      .queryRenderedFeatures()
      .filter((f) => get(f.layer, 'metadata["kinderkiez:type"]') === "MOVABLE");

    const placedFeatures = features.map((feature) => {
      const {
        type,
        geometry,
        layer: { id: layerId },
      } = feature;
      const id = uniqid();

      const layout = style.layers.find((layer) => layer.id === layerId)?.layout;

      const icon = layout?.["icon-image"]
        ? expression
            .createExpression(layout["icon-image"])
            .value.evaluate({}, feature)
        : "";

      return {
        type,
        properties: {
          icon,
          id,
          size: 1,
          rotation: 0,
        },
        geometry,
      };
    }) as Feature<Point>[];

    dispatchStyle({
      type: "UPDATE_PLACED_ICONS",
      features: placedFeatures,
    });

    dispatchStyle({
      type: "HIDE_SOURCE_ICON_LAYERS",
    });

    onFeaturePlacement?.(placedFeatures);
  }, [mapRef, state]);

  /**
   * Render map when render prop is true
   * @todo: Merge with imperative render function
   */
  useEffect(() => {
    if (render) {
      const map = mapRef.current.getMap();
      map.once("render", function() {
        map.getCanvas().toBlob(
          (data) => {
            const url = URL.createObjectURL(data);
            onRender?.(url);
          },
          "image/jpeg",
          1
        );
      });
      map.triggerRepaint();
    }
  }, [mapRef, render]);

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);

    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [activeIcon, canManipulate]);

  return (
    <div
      ref={containerRef}
      className={cn([
        styles.container,
        usedFormat.round && styles.containerRound,
        usedFormat.poster && styles.containerPoster,
      ])}
      //@ts-ignore
      style={{ "--scale": 1 / scale }}
    >
      <Head>
        <link
          rel="stylesheet"
          href="https://api.tiles.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css"
        />
      </Head>
      <ReactMapGL
        {...viewport}
        pitch={0}
        ref={mapRef}
        onViewportChange={(viewport) =>
          state !== "DESIGN" &&
          !isMobile &&
          onViewportChange?.({ ...viewport, transitionDuration: 0 })
        }
        attributionControl={true}
        mapboxApiAccessToken="pk.eyJ1IjoiZmVsaXhzdG9jayIsImEiOiJjam4xZXI4dnI0azFxM3hxY3Iza2R2cWhrIn0.C8cIJGklRSy5W4gkrSJaCg"
        width={usedFormat.width}
        height={usedFormat.height}
        maxZoom={16.99}
        minZoom={16}
        doubleClickZoom={false}
        touchZoom={false}
        touchRotate={false}
        dragRotate={false}
        dragPan={canNavigate}
        scrollZoom={false}
        mapStyle={style}
        onHover={(e) => {
          if (!e.features) return;

          setInteractable(
            canManipulate &&
              e.features.some((feature) =>
                activeIcon
                  ? feature.properties.id === activeIcon.properties.id
                  : feature.layer.id === "placedIcons"
              )
          );
        }}
        onClick={onClick}
        onMouseUp={onUp}
        onMouseDown={onDown}
        onMouseMove={onMove}
        onTouchStart={onDown}
        onTouchEnd={onUp}
        onTouchMove={onMove}
        getCursor={({ isDragging }) => {
          return state === "DESIGN"
            ? interactable
              ? activeIcon
                ? dragging
                  ? "grabbing"
                  : "grab"
                : "pointer"
              : "auto"
            : isDragging
            ? canNavigate
              ? "grabbing"
              : "auto"
            : interactable
            ? "pointer"
            : canNavigate
            ? "grab"
            : "auto";
        }}
      >
        <BadgeOverlay
          position={usedFormat.round ? "CENTER" : "RIGHT"}
          inFrame={usedFormat.poster}
        />
        {canNavigate && (
          <NavigationControl
            viewport={viewport as ViewportProps}
            scale={scale}
            position={usedFormat.round ? "center" : "top"}
            onViewportChange={onViewportChange}
          />
        )}

        {canManipulate && (
          <>
            {activeIcon && (
              <IconControls
                longitude={activeIcon.geometry.coordinates[0]}
                latitude={activeIcon.geometry.coordinates[1]}
                scale={scale}
                size={activeIcon.properties.size}
                onScaleUp={() => dispatchActiveIcon({ type: "INCREASE_SIZE" })}
                onScaleDown={() =>
                  dispatchActiveIcon({ type: "DECREASE_SIZE" })
                }
                onRotateLeft={() => dispatchActiveIcon({ type: "ROTATE_LEFT" })}
                onRotateRight={() =>
                  dispatchActiveIcon({ type: "ROTATE_RIGHT" })
                }
                onMirror={() => dispatchActiveIcon({ type: "MIRROR" })}
                onDelete={() => onDeleteFeature(activeIcon)}
                onClose={() => {
                  dispatchActiveIcon({ type: "RESET" });
                }}
              />
            )}

            <DragOverlay
              onDrop={async (e, { coordinates }, map) => {
                const icon = e.dataTransfer.getData("map/icon-id");
                addIcon(icon, coordinates);
              }}
            />
          </>
        )}
      </ReactMapGL>
    </div>
  );
};

export const Map = React.forwardRef(MapComponent);
