import debounce from "lodash/debounce";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./ScaleContainer.css";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { Button } from "./Button";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "../helpers/translation";
import * as _screenfull from "screenfull";

const screenfull = _screenfull as _screenfull.Screenfull;

interface Props {
  /** Padding from the sides  */
  padding?: number;
  scale: number;
  onScaleUpdate: (scale: number) => void;
}

export const ScaleContainer: FunctionComponent<Props> = ({
  children,
  scale,
  onScaleUpdate,
  padding = 60,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const calculateScale = debounce(() => {
    if (!containerRef.current || !contentRef.current) return;

    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerRef.current.clientHeight;
    const contentWidth = contentRef.current.clientWidth;
    const contentHeight = contentRef.current.clientHeight;

    const scaleX = (containerWidth - padding) / contentWidth;
    const scaleY = (containerHeight - padding) / contentHeight;

    // Maximum scale is 1
    onScaleUpdate(
      Math.min(parseFloat(scaleX.toFixed(2)), parseFloat(scaleY.toFixed(2)), 1)
    );

    setIsFullscreen(!!document.fullscreenElement);
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", calculateScale);
    screenfull.isEnabled && screenfull.on("change", calculateScale);

    return () => {
      window.removeEventListener("resize", calculateScale);
      screenfull.isEnabled && screenfull.off("change", calculateScale);
    };
  }, [containerRef, contentRef]);

  useEffect(() => {
    if (containerRef.current && contentRef.current) calculateScale();
  }, [containerRef, contentRef, children]);

  return (
    <div ref={containerRef} className={styles.container}>
      {screenfull.isEnabled && (
        <Button
          className={styles.fullscreenButton}
          size="small"
          tertiary
          onClick={() => {
            if (screenfull.isFullscreen) {
              screenfull.exit();
            } else {
              screenfull.request(containerRef.current);
            }
          }}
        >
          <Tooltip
            title={t("ui_toggle-fullscreen")}
            PopperProps={{ disablePortal: true }}
            placement="left"
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </Tooltip>
        </Button>
      )}
      <div
        ref={contentRef}
        className={styles.content}
        style={{ transform: `translate(-50%, -50%) scale(${scale})` }}
      >
        {children}
      </div>
    </div>
  );
};
