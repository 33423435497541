import Logo from "../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import styles from "./Header.css";
import { Button } from "./Button";
import React, { FunctionComponent, ReactNode } from "react";
import ShareIcon from "@material-ui/icons/Share";
import { isMobile } from "react-device-detect";

interface Props {
  hideSaveButton?: boolean;
  canSave?: boolean;
  onSave?: () => any;
  onDeluxeClick?: () => any;
  title?: ReactNode;
}

export const Header: FunctionComponent<Props> = ({
  hideSaveButton,
  canSave,
  onSave,
  onDeluxeClick,
  title,
}) => {
  const { i18n, t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <a href="https://kinderkiez.net">
          <img width={100} src={Logo} alt="kinderkiez" />
        </a>

        <p className={styles.title}>
          {title ? title : t("introduction_title")}
        </p>

        {/* <Button round size="small" onClick={onDeluxeClick}>
          {t("deluxe-modal_cta")}
        </Button> */}
      </div>

      <div className={styles.navigation}>
        <Button
          tertiary
          size="small"
          onClick={() =>
            i18n.changeLanguage(i18n.language === "de" ? "en" : "de")
          }
        >
          {i18n.language === "en"
            ? t("language_german")
            : t("language_english")}
        </Button>
        {!hideSaveButton && (
          <Button
            disabled={!canSave}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              onSave && onSave();
            }}
          >
            {isMobile ? (
              <ShareIcon
                style={{ margin: -8 }}
                titleAccess={t("button_save")}
              />
            ) : (
              t("button_save")
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
