export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDc5WptJRGzDdYFiOh8HVEShrZ6xtTEVqM",
  authDomain: "kinderkiez-dev.firebaseapp.com",
  databaseURL: "https://kinderkiez-dev.firebaseio.com",
  projectId: "kinderkiez-dev",
  storageBucket: "kinderkiez-dev.appspot.com",
  messagingSenderId: "219947501333",
  appId: "1:219947501333:web:c65869545b65483be84b1c",
  measurementId:
    process.env.ENVIRONMENT === "production"
      ? "UA-127587042-1"
      : "UA-127587042-2"
};

export const BACKUP_KEY = "CONFIGURATION_BACKUP";
