import { ButtonBase } from "@material-ui/core";
import cn from "classnames";
import { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import grabIcon from "../assets/icons/grab.svg";
import moveIcon from "../assets/icons/move.svg";
import rotateRightIcon from "../assets/icons/rotate_right.svg";
import trashIcon from "../assets/icons/trash.svg";
import zoomInIcon from "../assets/icons/zoom_in.svg";
import { FormatId, FORMATS } from "../constants/formats";
import { STEPS } from "../constants/steps";
import { Overrides } from "../ducks/style";
import { Button } from "./Button";
import { FileSystem } from "./FileSystem";
import { FormatSelector } from "./FormatSelector";
import { Geocoder } from "./Geocoder";
import { InfoBox } from "./InfoBox";
import { OverrideSelector } from "./OverrideSelector";
import { PREFILLS, PrefillSelector } from "./PrefillSelector";
import { Accordion } from "./style/Accordion";
import { Tip } from "./Tip";
import styles from "./UI.css";

const formatter = Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const FlexContainer = ({ open, children }) => {
  return (
    <div
      style={{
        flex: open ? 1 : undefined,
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
};

interface Props {
  state: typeof STEPS[number];
  activeStep: typeof STEPS[number];
  format: FormatId;
  prefill: typeof PREFILLS[number];
  dirty: boolean;
  overrides: Overrides;
  onStateChange: (step: typeof STEPS[number]) => any;
  onLocationChange: (value: any) => any;
  onFormatChange: (format: FormatId) => any;
  onPrefillChange: (prefill: typeof PREFILLS[number]) => any;
  onFileSelect: (icon: string) => any;
  onFeatureSearch: (query: string) => any;
  onCategorySelect: (label: string) => any;
  onStreetLabelSpacingUpdate: (spacing: number) => any;
  onBackgroundColorUpdate: (color: string) => any;
  onPathVisibilityUpdate: (status: boolean) => any;
  onContinueEdit: () => any;
  onReset: () => any;
  onSave: () => any;
  onSubmit: () => any;
}

export const UI: FunctionComponent<Props> = ({
  state,
  activeStep,
  format,
  prefill,
  dirty,
  overrides,
  onStateChange,
  onLocationChange,
  onFormatChange,
  onPrefillChange,
  onFileSelect,
  onFeatureSearch,
  onCategorySelect,
  onStreetLabelSpacingUpdate,
  onBackgroundColorUpdate,
  onPathVisibilityUpdate,
  onContinueEdit,
  onReset,
  onSave,
  onSubmit,
}) => {
  const { t } = useTranslation();

  let action = (
    <Button pulse={dirty} expanded onClick={onSubmit} disabled={!dirty}>
      <div className={styles.checkoutButton}>
        <span>{t("order_add-to-cart")}</span>
        <h2>{formatter.format(FORMATS[format].price)}</h2>
      </div>
    </Button>
  );

  if (activeStep !== "DESIGN" || !dirty) {
    switch (state) {
      case "LOCATION":
        action = (
          <Button pulse expanded onClick={() => onStateChange("FORMAT")}>
            {t("section_location_next")}
          </Button>
        );
        break;
      case "FORMAT":
        action = (
          <Button pulse expanded onClick={() => onStateChange("FILLING")}>
            {t("section_size_next")}
          </Button>
        );
        break;
      case "FILLING":
        action = isMobile ? (
          <Button pulse expanded onClick={() => onSave()}>
            {t("preview_save")}
          </Button>
        ) : (
          <Button pulse expanded onClick={() => onStateChange("DESIGN")}>
            {t("section_prefill_next")}
          </Button>
        );
        break;
    }
  }

  const visibleSteps = STEPS.slice(
    0,
    STEPS.findIndex((f) => f == activeStep) + 1
  );

  return (
    <div className={styles.container}>
      {state === "PREVIEW" ? (
        <div className={styles.scrollContent}>
          <div className={styles.content}>
            <h2 className={styles.uiTitle}>{t("section_preview_info_hl")}</h2>
            <p>{t("section_preview_info")}</p>
            <h3 className={styles.uiTitle}>
              {t("section_preview_info-design")}
            </h3>
            {!isMobile && (
              <>
                <ButtonBase
                  className={styles.button}
                  onClick={() => onContinueEdit()}
                >
                  <h3>{t("section_preview_cta-continue")}</h3>
                </ButtonBase>
                <small
                  style={{
                    display: "block",
                    margin: "0.5rem",
                    textAlign: "center",
                  }}
                >
                  {t("or")}
                </small>
              </>
            )}
            <ButtonBase
              className={cn([styles.button, styles.buttonSecondary])}
              onClick={() => onReset()}
            >
              <h3>{t("section_preview_cta-reset")}</h3>
            </ButtonBase>
          </div>
        </div>
      ) : (
        visibleSteps.map((step) => {
          const infoBoxOpen = false;
          switch (step) {
            case "LOCATION":
              return (
                <FlexContainer key={step} open={state === "LOCATION"}>
                  <Accordion
                    title={t("section_location_title")}
                    open={state === "LOCATION"}
                    onToggle={() => onStateChange("LOCATION")}
                  >
                    <InfoBox defaultOpen={infoBoxOpen}>
                      <h3>{t("section_location_info_hl")}</h3>
                      <div>{t("section_location_info")}</div>
                      <ul className={styles.infoList}>
                        {!isMobile && (
                          <li>
                            <img
                              src={grabIcon}
                              className={styles.infoIcon}
                              alt="Grab"
                            />
                            {t("section_location_move")}
                          </li>
                        )}
                        <li>
                          <img
                            src={zoomInIcon}
                            className={styles.infoIcon}
                            alt="Zoom"
                          />
                          {t("section_location_zoom")}
                        </li>
                        <li>
                          <img
                            src={rotateRightIcon}
                            className={styles.infoIcon}
                            alt="Rotate"
                          />
                          {t("section_location_rotate")}
                        </li>
                      </ul>
                    </InfoBox>
                    <div className={styles.content}>
                      <Geocoder
                        onSelect={({ value, label }) => {
                          TagManager.dataLayer({
                            dataLayer: {
                              event: "genericEvent",
                              eventCategory: "Editor",
                              eventAction: "Selected address",
                              eventLabel: label,
                              eventValue: value.join(","),
                            },
                          });
                          onLocationChange(value);
                        }}
                      />
                    </div>
                  </Accordion>
                </FlexContainer>
              );
            case "FORMAT":
              return (
                <FlexContainer key={step} open={state === "FORMAT"}>
                  <Accordion
                    title={t("section_size_title")}
                    open={state === "FORMAT"}
                    onToggle={() => onStateChange("FORMAT")}
                  >
                    <InfoBox defaultOpen={infoBoxOpen}>
                      <h3>{t("section_size_info_hl")}</h3>
                      <div>{t("section_size_info")}</div>
                    </InfoBox>
                    <FormatSelector
                      selected={format}
                      onSelect={onFormatChange}
                    />
                    <Tip>{t("section_size_tip")}</Tip>
                  </Accordion>
                </FlexContainer>
              );
            case "FILLING":
              return (
                <FlexContainer key={step} open={state === "FILLING"}>
                  <Accordion
                    title={t("section_prefill_title")}
                    open={state === "FILLING"}
                    onToggle={() => onStateChange("FILLING")}
                  >
                    <InfoBox defaultOpen={infoBoxOpen}>
                      <h3>{t("section_prefill_info_hl")}</h3>
                      <div>{t("section_prefill_info")}</div>
                    </InfoBox>
                    <div className={styles.content}>
                      <PrefillSelector
                        dirty={dirty}
                        selected={prefill}
                        onChange={onPrefillChange}
                      />

                      <OverrideSelector
                        streetLabelSpacing={overrides.streetLabelSpacing}
                        backgroundColor={overrides.backgroundColor}
                        hiddenPaths={overrides.hidePaths}
                        onStreetLabelSpacingUpdate={onStreetLabelSpacingUpdate}
                        onBackgroundColorUpdate={onBackgroundColorUpdate}
                        onHiddenPathsUpdate={onPathVisibilityUpdate}
                      />
                      <Tip>{t("section_prefill_tip")}</Tip>
                    </div>
                  </Accordion>
                </FlexContainer>
              );
            case "DESIGN":
              return (
                <FlexContainer key={step} open={state === "DESIGN"}>
                  <Accordion
                    title={t("section_placing_title")}
                    open={state === "DESIGN"}
                    onToggle={() => onStateChange("DESIGN")}
                  >
                    <InfoBox defaultOpen={infoBoxOpen}>
                      <h3>{t("section_placing_info_hl")}</h3>
                      <ul className={styles.infoList}>
                        <li>
                          <img
                            src={grabIcon}
                            className={styles.infoIcon}
                            alt=""
                          />
                          {t("section_placing_info_add")}
                        </li>
                        <li>
                          <img
                            src={moveIcon}
                            className={styles.infoIcon}
                            alt=""
                          />
                          {t("section_placing_info_move")}
                        </li>
                        <li>
                          <img
                            src={trashIcon}
                            className={styles.infoIcon}
                            alt=""
                          />
                          {t("section_placing_info_delete")}
                        </li>
                        <li>
                          <img
                            src={zoomInIcon}
                            className={styles.infoIcon}
                            alt=""
                          />
                          {t("section_placing_info_scale")}
                        </li>
                        <li>
                          <img
                            src={rotateRightIcon}
                            className={styles.infoIcon}
                            alt=""
                          />
                          {t("section_placing_info_rotate")}
                        </li>
                      </ul>
                    </InfoBox>
                    <FileSystem
                      onSelect={onFileSelect}
                      onSearch={onFeatureSearch}
                      onCategoryOpen={(label, open) =>
                        open && onCategorySelect(label)
                      }
                    />
                  </Accordion>
                </FlexContainer>
              );
            default:
              return null;
          }
        })
      )}

      <div className={styles.actions}>{action}</div>
    </div>
  );
};
