import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { Modal } from "./Modal";
import { useState } from "react";

export const MobileModal = () => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);

  return (
    <Modal
      title={t("mobile_sorry")}
      show={!hidden}
      actions={<Button onClick={() => setHidden(true)}>OK</Button>}
    >
      <p style={{ textAlign: "center" }}>{t("mobile_message")}</p>
    </Modal>
  );
};
