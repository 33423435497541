import React, { Dispatch, ReducerAction, ReducerState } from "react";
import { activeIconReducer } from "../ducks/activeIcon";
import { styleReducer } from "../ducks/style";

export const MapStyleContext = React.createContext<
  [
    ReducerState<typeof styleReducer>,
    Dispatch<ReducerAction<typeof styleReducer>>
  ]
>([undefined, undefined]);

export const ActiveIconContext = React.createContext<
  [
    ReducerState<typeof activeIconReducer>,
    Dispatch<ReducerAction<typeof activeIconReducer>>
  ]
>([undefined, undefined]);
