import { FunctionComponent } from "react";
import { Format } from "../constants/formats";
import styles from "./FormatButton.css";
import cn from "classnames";
import { useTranslation } from "react-i18next";

const formatter = Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

interface Props {
  id: string;
  format: Format;
  selected: boolean;
  onSelect: (format: string) => void;
}

export const FormatButton: FunctionComponent<Props> = ({
  id,
  format,
  selected,
  onSelect,
}) => {
  const { t } = useTranslation();
  return (
    <a
      className={cn([styles.container, selected && styles.containerSelected])}
      onClick={(e) => {
        e.preventDefault();
        onSelect(id);
      }}
      title={t(`size_title--${id.toLowerCase()}`)}
    >
      <img
        className={styles.image}
        src={require(`../assets/images/format-${id.toLowerCase()}.svg`)}
        height="100"
        alt={t(`size_title--${id.toLowerCase()}`)}
      />

      <div className={styles.size}>
        {format.round
          ? `⌀${format.displayWidth}cm`
          : `${format.displayWidth}cm × ${format.displayHeight}cm`}
      </div>

      <div className={styles.price}>{formatter.format(format.price)}</div>
    </a>
  );
};
