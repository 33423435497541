import styles from "./ErrorBar.css";

export const ErrorBar = ({ message, onClose }) => {
  return (
    <div className={styles.container}>
      {message}{" "}
      <button className={styles.button} onClick={onClose}>
        ✕
      </button>
    </div>
  );
};
