import React from "react";
import { BaseControl, BaseControlProps, ViewportProps } from "react-map-gl";
import RotateLeftIcon from "../../assets/icons/rotate_left.svg";
import RotateRightIcon from "../../assets/icons/rotate_right.svg";
import ZoomInIcon from "../../assets/icons/zoom_in.svg";
import ZoomOutIcon from "../../assets/icons/zoom_out.svg";
import styles from "./NavigationControl.css";
import Repeatable from "react-repeatable";
import cn from "classnames";

export type NavigationControlProps = BaseControlProps & {
  className?: string;
  viewport?: ViewportProps;
  position?: "top" | "center";
  scale?: number;
  onViewportChange?: (state: ViewportProps) => any;
};

const ROTATION_SPEED = 3;
const ZOOM_SPEED = 0.25;
const REPEAT_INTERVAL = 100;

export class NavigationControl extends BaseControl<
  NavigationControlProps,
  HTMLDivElement
> {
  _render() {
    const {
      className,
      viewport,
      onViewportChange,
      position = "TOP",
      scale = 1
    } = this.props;

    return (
      <div
        className={cn([
          `mapboxgl-ctrl`,
          `mapboxgl-ctrl-group`,
          className,
          styles.container
        ])}
        ref={this._containerRef}
        style={{
          position: "absolute",
          ...(position === "center"
            ? {
                top: "50%",
                transform: `scale(${1 / scale}) translateY(-50%)`
              }
            : { top: "1rem", transform: `scale(${1 / scale})` }),
          left: "1rem",
          transformOrigin: "top left"
        }}
      >
        <Repeatable
          tag="button"
          type="button"
          repeatInterval={REPEAT_INTERVAL}
          onPress={() =>
            onViewportChange({
              ...viewport,
              bearing: viewport.bearing - ROTATION_SPEED,
              transitionDuration: 200
            })
          }
          onHold={() =>
            onViewportChange({
              ...viewport,
              bearing: viewport.bearing - ROTATION_SPEED,
              transitionDuration: REPEAT_INTERVAL
            })
          }
        >
          <img className={styles.icon} src={RotateLeftIcon} alt="" />
        </Repeatable>
        <Repeatable
          tag="button"
          type="button"
          repeatInterval={REPEAT_INTERVAL}
          onPress={() =>
            onViewportChange({
              ...viewport,
              bearing: viewport.bearing + ROTATION_SPEED,
              transitionDuration: 200
            })
          }
          onHold={() =>
            onViewportChange({
              ...viewport,
              bearing: viewport.bearing + ROTATION_SPEED,
              transitionDuration: REPEAT_INTERVAL
            })
          }
        >
          <img className={styles.icon} src={RotateRightIcon} alt="" />
        </Repeatable>
        <Repeatable
          tag="button"
          type="button"
          repeatInterval={REPEAT_INTERVAL}
          onPress={() =>
            onViewportChange({
              ...viewport,
              zoom: viewport.zoom + ZOOM_SPEED,
              transitionDuration: 200
            })
          }
          onHold={() =>
            onViewportChange({
              ...viewport,
              zoom: viewport.zoom + ZOOM_SPEED,
              transitionDuration: REPEAT_INTERVAL
            })
          }
        >
          <img className={styles.icon} src={ZoomInIcon} alt="" />
        </Repeatable>
        <Repeatable
          tag="button"
          type="button"
          repeatInterval={REPEAT_INTERVAL}
          onPress={() =>
            onViewportChange({
              ...viewport,
              zoom: viewport.zoom - ZOOM_SPEED,
              transitionDuration: 200
            })
          }
          onHold={() =>
            onViewportChange({
              ...viewport,
              zoom: viewport.zoom - ZOOM_SPEED,
              transitionDuration: REPEAT_INTERVAL
            })
          }
        >
          <img className={styles.icon} src={ZoomOutIcon} alt="" />
        </Repeatable>
      </div>
    );
  }
}
