import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Slider,
  Switch,
  Tooltip,
  withStyles,
  IconButton
} from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BACKGROUND_COLORS } from "../constants/map";
import styles from "./OverrideSelector.css";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { ToggleButton } from "@material-ui/lab";
import { ToggleButtonGroup } from "@material-ui/lab";
import { isMobile } from "react-device-detect";

interface Props {
  streetLabelSpacing: number;
  backgroundColor: string;
  hiddenPaths: boolean;
  onStreetLabelSpacingUpdate: (value: number) => any;
  onBackgroundColorUpdate: (color: keyof typeof BACKGROUND_COLORS) => any;
  onHiddenPathsUpdate: (hidden: boolean) => any;
}

const MySlider = withStyles(({ palette }) => ({
  root: {
    width: "auto",
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1
  },
  mark: {
    display: "none"
  },
  markLabel: {
    top: 32
  }
}))(Slider);

const SPACING_MIN = 500;
const SPACING_MAX = 900;
const SPACING_STEP = 20;

export const OverrideSelector: FunctionComponent<Props> = ({
  streetLabelSpacing,
  backgroundColor,
  hiddenPaths,
  onStreetLabelSpacingUpdate,
  onBackgroundColorUpdate,
  onHiddenPathsUpdate
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl fullWidth margin="normal">
        <FormLabel>{t("overrides_backgroundColor")}</FormLabel>
        <ToggleButtonGroup
          className={styles.colorGrid}
          value={backgroundColor}
          onChange={(e, value) => {
            onBackgroundColorUpdate(value);
          }}
          exclusive
        >
          {Object.entries(BACKGROUND_COLORS).map(([name, color]) => (
            <ToggleButton
              key={name}
              value={color}
              classes={{
                root: styles.colorSwatch,
                selected: styles.colorSwatchSelected
              }}
              className={styles.colorSwatch}
            >
              <Tooltip title={t(`color__${name}`)}>
                <div
                  className={styles.colorSwatch__preview}
                  style={{ backgroundColor: color }}
                />
              </Tooltip>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </FormControl>
      {!isMobile && (
        <>
          <FormControl fullWidth margin="normal">
            <FormLabel>{t("overrides_streetLabelPlacement")}</FormLabel>
            <div className={styles.sliderGrid}>
              <IconButton
                size="small"
                disabled={streetLabelSpacing === SPACING_MIN}
                onClick={() =>
                  onStreetLabelSpacingUpdate(
                    Math.max(streetLabelSpacing - 20, SPACING_MIN)
                  )
                }
              >
                <ArrowBack />
              </IconButton>
              <MySlider
                value={streetLabelSpacing}
                step={SPACING_STEP}
                min={SPACING_MIN}
                max={SPACING_MAX}
                onChange={(e, value) =>
                  onStreetLabelSpacingUpdate(value as number)
                }
              />
              <IconButton
                size="small"
                disabled={streetLabelSpacing === SPACING_MAX}
                onClick={() =>
                  onStreetLabelSpacingUpdate(
                    Math.min(streetLabelSpacing + 20, SPACING_MAX)
                  )
                }
              >
                <ArrowForward />
              </IconButton>
            </div>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel>{t("overrides_backgroundObjects")}</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={hiddenPaths}
                    onChange={e => onHiddenPathsUpdate(e.target.checked)}
                  />
                }
                label={t("overrides_hiddenPaths")}
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </>
      )}
    </>
  );
};
