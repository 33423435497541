import geocoder, {
  GeocodeService
} from "@mapbox/mapbox-sdk/services/geocoding";
import { useRef, useEffect, useState } from "react";
import Async from "react-select/async";
import debounce from "lodash/debounce";
import { LngLat } from "mapbox-gl";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";

export function Geocoder({ onSelect }) {
  const geocoderRef = useRef<GeocodeService>();
  const [locating, setLocating] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    geocoderRef.current = geocoder({
      accessToken:
        "pk.eyJ1IjoiZmVsaXhzdG9jayIsImEiOiJjam4xZXI4dnI0azFxM3hxY3Iza2R2cWhrIn0.C8cIJGklRSy5W4gkrSJaCg"
    });
  }, []);

  const geocode = async query => {
    const result = await geocoderRef.current
      .forwardGeocode({
        mode: "mapbox.places",
        language: [i18n.language === "en" ? "en-GB" : "de-DE"],
        query
      })
      .send();

    const results: [
      { value: LngLat; label: string }
    ] = result.body.features.map(feature => ({
      value: feature.center,
      label: feature.place_name
    }));

    return results;
  };

  function onLocate() {
    setLocating(true);
    navigator.geolocation.getCurrentPosition(
      position => {
        onSelect({
          value: [position.coords.longitude, position.coords.latitude],
          label: "Located"
        });
        setLocating(false);
      },
      () => {
        setLocating(false);
      }
    );
  }

  const loadOptions = debounce((inputValue, callback) => {
    try {
      geocode(inputValue).then(results => {
        callback(results);
      });
    } catch (e) {}
  }, 500);

  return (
    <>
      <Async
        instanceId="geocoder"
        cacheOptions
        loadOptions={loadOptions}
        onChange={value => onSelect(value)}
        placeholder={t("geolocate_enter-address")}
        noOptionsMessage={() => null}
        loadingMessage={() => t("loading")}
      />
      <small
        style={{ display: "block", margin: "0.5rem", textAlign: "center" }}
      >
        {t("or")}
      </small>
      <Button
        disabled={locating}
        expanded
        secondary
        size="small"
        onClick={onLocate}
      >
        {locating ? t("geolocate_locating") : t("geolocate_locate")}
      </Button>
    </>
  );
}
