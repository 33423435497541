import React from "react";
import { BaseControl, BaseControlProps } from "react-map-gl";
import BadgeImage from "../assets/images/kinderkiez-badge.svg";
import styles from "./BadgeOverlay.css";
import cn from "classnames";

interface Props extends BaseControlProps {
  position?: "CENTER" | "RIGHT";
  inFrame?: boolean;
}

export class BadgeOverlay extends BaseControl<Props, HTMLDivElement> {
  _render() {
    const { position, inFrame } = this.props;
    return (
      <div
        ref={this._containerRef}
        className={cn([
          styles.container,
          position === "RIGHT" && styles.rightAligned,
          inFrame && styles.bottom,
        ])}
      >
        <img className={styles.image} src={BadgeImage} alt="kinderkiez" />
      </div>
    );
  }
}
