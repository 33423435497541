import InfoIcon from "../assets/icons/info.svg";
import styles from "./Tip.css";

export const Tip = ({ children }) => {
  return (
    <div className={styles.tip}>
      <img className={styles.tipIcon} src={InfoIcon} alt="" />
      <div>{children}</div>
    </div>
  );
};
