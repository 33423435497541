import {
  createMuiTheme,
  MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core";
import { easeCubicInOut } from "d3-ease";
import downscale from "downscale";
//
//
//
//

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc as firestoreDoc,
  addDoc,
  updateDoc,
  getDoc,
  Firestore,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  FirebaseStorage,
  getMetadata,
} from "firebase/storage";

// L 40552001044502
//
//
//
import { Feature, Point } from "geojson";
import { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import queryString from "query-string";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { FlyToInterpolator, ViewportProps, ViewState } from "react-map-gl";
import Modal from "react-modal";
import NoSSR from "react-no-ssr";
import uniqid from "uniqid";
import { ErrorBar } from "../components/ErrorBar";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";
import { Map, MapState } from "../components/Map";
import { MobileModal } from "../components/MobileModal";
import { OrderModal } from "../components/OrderModal";
import { SavingModal } from "../components/SavingModal";
import { ScaleContainer } from "../components/ScaleContainer";
import { UI } from "../components/UI";
import { BACKUP_KEY, FIREBASE_CONFIG } from "../constants/firebase";
import { FormatId, FORMATS } from "../constants/formats";
import { Configuration, BACKGROUND_COLORS } from "../constants/map";
import { STEPS } from "../constants/steps";
import { MapStyleContext } from "../contexts/map";
import {
  getStyleFeatures,
  getStyleOverrides,
  styleReducer as styleReducerFunction,
} from "../ducks/style";
import { loadImage, PREVIEW_IMAGE_WIDTH } from "../helpers/loadImage";
import { styleWithDynamicLayers } from "../helpers/map";
import { useViewportUnits } from "../helpers/useViewportUnits";
import baseStyle from "../map/style.json";
// import { DeluxeModal } from "../components/DeluxeModal";

const style = styleWithDynamicLayers(baseStyle as mapboxgl.Style);
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#e69073",
      dark: "#a16450",
      light: "#eba68f",
    },
  },
});

Modal.setAppElement("#__next");

const MapPage: NextPage = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const database = useRef<Firestore>();
  const storage = useRef<FirebaseStorage>();
  const [step, setStep] = useState<typeof STEPS[number]>();
  const [loading, setLoading] = useState(false);
  const [setupLoading, setSetupLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDeluxeModal, setShowDeluxeModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [saving, setSaving] = useState(false);
  const [format, setFormat] = useState<FormatId>(isMobile ? "SMALL" : "MEDIUM");
  const [scale, setScale] = useState<number>(0.5);
  const [activeStep, setActiveStep] = useState<typeof STEPS[number]>(
    "LOCATION"
  );
  const [existingConfig, setExistingConfig] = useState<Configuration>();
  const [configId, setConfigId] = useState<string>();

  const [viewport, setViewport] = useState<ViewState | ViewportProps>({
    longitude: 13.38333,
    latitude: 52.51667,
    zoom: 16.5,
    bearing: 0,
  });
  const [prefill, setPrefill] = useState("FULL");
  const mapRef = useRef<{
    render: () => Promise<string>;
    dispatchActiveIcon: (...args: any[]) => void;
  }>();

  const [mapStyle, dispatchMapStyle] = useReducer(styleReducerFunction, style);

  useEffect(() => {
    // if (!firebase.apps.length) firebase.initializeApp(FIREBASE_CONFIG);

    const app = initializeApp(FIREBASE_CONFIG);
    database.current = getFirestore(app);
    storage.current = getStorage();

    // database.current = firebase
    //   .firestore()
    //   .collection("configurations");
    // storage.current = firebase.storage().ref("configurations");

    const qs = queryString.parse(window.location.search);

    const id = qs.id?.toString();
    const step = qs.step?.toString();
    const format = qs.format?.toString().toUpperCase();
    const bg = qs.bg?.toString();
    const longitude = qs.lon?.toString();
    const latitude = qs.lat?.toString();
    const hasCoordinates = longitude && latitude;
    const canRestore = !id && !hasCoordinates;

    checkForExistingConfig(id, step, canRestore);

    // If lat&lon parameters are set we go to the destination
    if (longitude && latitude) {
      setViewport({
        ...viewport,
        longitude: parseFloat(longitude),
        latitude: parseFloat(latitude),
        zoom: 16.5,
      });
    }

    if (step === "format") {
      onStateChange("FORMAT");
    }

    if (step === "prefill") {
      onStateChange("FILLING");
    }

    if (format && !!FORMATS[format]) {
      setFormat(format);
    }

    if (bg && !!BACKGROUND_COLORS[bg]) {
      onBackgroundColorUpdate(BACKGROUND_COLORS[bg]);
    }

    process.env.NODE_ENV === "production" &&
      TagManager.initialize({
        gtmId: "GTM-PZ3G24F",
        dataLayer: {
          existingConfig:
            id || !!localStorage.getItem(BACKUP_KEY) ? "from Cache" : null,
        },
      });
  }, []);

  useEffect(() => {
    if (!step) return;
    let query = queryString.parse(window.location.search);
    query.step = showOrderModal
      ? "purchase"
      : saving
      ? "save"
      : step.toLowerCase();

    if (!configId) {
      delete query.id;
    }

    const url = {
      pathname: router.pathname,
      query,
    };
    router.replace(url, url, { shallow: true });
    TagManager.dataLayer({
      dataLayer: {
        event: "virtualPageView",
        virtualPageURL: "/?" + queryString.stringify(query),
        virtualPageTitle: showOrderModal ? "PURCHASE" : saving ? "SAVE" : step,
      },
    });
  }, [step, saving, showOrderModal]);

  useViewportUnits();

  // Save changes to localStorage
  useEffect(() => {
    try {
      const data = buildFeatureData();
      if (data.features.length) {
        localStorage.setItem(BACKUP_KEY, JSON.stringify(data));
      }
    } catch (e) {}
  }, [mapStyle]);

  const isDirty = !!getStyleFeatures(mapStyle).length;
  const overrides = getStyleOverrides(mapStyle);

  function buildFeatureData(type: "SAVED" | "ORDER" = "SAVED") {
    const features = getStyleFeatures(mapStyle);
    const overrides = getStyleOverrides(mapStyle);

    return {
      type: "FeatureCollection",
      properties: {
        viewport: {
          latitude: viewport.latitude,
          longitude: viewport.longitude,
          zoom: viewport.zoom,
          bearing: viewport.bearing,
        },
        format,
        step: activeStep,
        type,
        configuratorVersion: 3,
        overrides,
        prefill,
        createdAt: new Date(),
      },
      features,
    };
  }

  async function onSave() {
    try {
      setSaving(true);
      setShowModal(true);

      if (!configId) {
        const configId = await exportFeatures("SAVED");

        if (mapRef.current) {
          const renderedImage = await mapRef.current.render();

          const img = await loadImage(renderedImage);

          const data = await downscale(img, PREVIEW_IMAGE_WIDTH, 0, {
            returnBlob: true,
          });

          const strgRef = ref(
            storage.current,
            `configurations/${configId}.jpg`
          );

          try {
            await getMetadata(strgRef);
          } catch (e) {
            await uploadBytes(strgRef, data);
          }
        }

        setConfigId(configId);
      }
    } catch (e) {
      console.error(e);
      setErrorMessage(t("errormessage_config-save-failed"));
      setSaving(false);
      setShowModal(false);
    }
  }

  async function onPurchase() {
    try {
      setLoading(true);
      const configId = await exportFeatures("ORDER");

      setConfigId(configId);

      if (mapRef.current) {
        const renderedImage = await mapRef.current.render();

        const img = await loadImage(renderedImage);

        const data = await downscale(img, PREVIEW_IMAGE_WIDTH, 0, {
          returnBlob: true,
        });

        const strgRef = ref(storage.current, `configurations/${configId}.jpg`);

        try {
          await getMetadata(strgRef);
        } catch (e) {
          await uploadBytes(strgRef, data);
        }
      }

      window.location.href = `https://kinderkiez.net/pages/cart?id=${FORMATS[format].shopifyId}&design=${configId}`;
    } catch (e) {
      setErrorMessage(t("errormessage_config-save-failed"));
      console.error(e);
    }

    setLoading(false);
  }

  async function checkForExistingConfig(
    id: string,
    step?: string,
    canRestore?: boolean
  ) {
    try {
      if (id) {
        const currentDoc = firestoreDoc(
          database.current,
          "configurations",
          id.toString()
        );
        const doc = await getDoc(currentDoc);

        if (doc.exists()) {
          const config = doc.data() as Configuration;
          setConfigId(id.toString());
          setExistingConfig(config);
          if (step === "design") {
            populateMap(config, false);
            setStep("DESIGN");
          } else if (step === "format") {
            populateMap(config, false);
            setStep("FORMAT");
          } else {
            populateMap(config);
          }

          const qs = queryString.parse(window.location.search);
          const format = qs.format?.toString().toUpperCase();
          if (format && !!FORMATS[format]) {
            setFormat(format);
          }
        } else {
          setErrorMessage(t("errormessage_config-load-failed"));
          setStep("LOCATION");
        }
      } else {
        const backupData = localStorage.getItem(BACKUP_KEY);

        if (backupData && canRestore) {
          const config: Configuration = JSON.parse(backupData);
          setExistingConfig(config);
          setShowModal(true);
        }

        setStep("LOCATION");
      }
    } catch (e) {
      setErrorMessage(t("errormessage_config-load-failed"));
      setStep("LOCATION");
    }

    if (window.navigator.userAgent.includes("Prerender")) {
      // Bail out if we're dealing with a prerenderer
      return;
    }

    setSetupLoading(false);
  }

  async function exportFeatures(type: "SAVED" | "ORDER" = "SAVED") {
    const data = buildFeatureData(type);

    const result = await addDoc(
      collection(database.current, "configurations"),
      data
    );

    return result.id;
  }

  async function populateMap(
    config: Configuration = existingConfig,
    preview = true
  ) {
    const { features, properties } = config;

    setViewport(properties.viewport);

    if (properties.prefill) {
      setPrefill(properties.prefill);
    }

    if (preview) {
      setStep("PREVIEW");
    } else {
      setStep(properties.step);
    }

    setFormat(properties.format);
    setActiveStep(properties.step);

    dispatchMapStyle({
      type: "APPLY_OVERRIDES",
      overrides: properties.overrides,
    });

    if (features?.length) {
      setTimeout(async () => {
        dispatchMapStyle({
          type: "HIDE_SOURCE_ICON_LAYERS",
        });

        dispatchMapStyle({
          type: "UPDATE_PLACED_ICONS",
          features,
        });
      });
    }
  }

  function onBackgroundColorUpdate(color: String) {
    resetConfig();
    dispatchMapStyle({
      type: "SET_BACKGROUND_COLOR",
      color,
    });
  }

  function onStateChange(step: typeof STEPS[number]) {
    if (STEPS.indexOf(step) > STEPS.indexOf(activeStep)) {
      setActiveStep(step);

      // Default custom events
      switch (step) {
        case "FORMAT":
          onFormatChange(format);
          break;
        case "FILLING":
          onPrefillChange(prefill);
          break;
      }
    }

    setStep(step);
  }

  function onFormatChange(format: string) {
    resetConfig();
    setFormat(format);
  }

  function onPrefillChange(prefill: string) {
    resetConfig();
    setPrefill(prefill);
  }

  function onLocationChange([longitude, latitude]: [number, number]) {
    resetConfig();
    setViewport({
      longitude,
      latitude,
      zoom: 16.5,
      bearing: 0,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubicInOut,
      transitionDuration: 1000,
    });
  }

  /**
   * reset config so it can be saved again
   */
  function resetConfig() {
    setConfigId(null);
  }

  function onFeatureSearch(query: string) {}

  function onFileSelect(icon: string) {
    const id = uniqid();
    const feature: Feature<Point> = {
      id,
      type: "Feature",
      properties: {
        id,
        icon,
      },
      geometry: {
        type: "Point",
        coordinates: [viewport.longitude, viewport.latitude],
      },
    };

    dispatchMapStyle({
      type: "ADD_POINT",
      feature,
    });

    mapRef.current?.dispatchActiveIcon?.({
      type: "SET",
      feature,
    });
  }

  function onCategorySelect(label: string) {}

  function onOrderCancel() {
    setShowOrderModal(false);
  }

  function onContinueEdit() {
    resetConfig();
    setStep(activeStep);
  }

  function onReset() {
    resetConfig();
    localStorage.removeItem(BACKUP_KEY);
    window.location.assign("/");
  }

  const imageScale = PREVIEW_IMAGE_WIDTH / FORMATS[format].width;

  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <MapStyleContext.Provider value={[mapStyle, dispatchMapStyle]}>
          <Head>
            <title>kinderkiez Designer</title>
            <link
              href="https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap"
              rel="stylesheet"
            />
            <meta
              property="og:title"
              content="kinderkiez Spielteppich | Selbst gestalten | Spielend lernen"
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="Der personalisierte Spielteppich von der eigenen Nachbarschaft 🏘️"
            />
            <meta property="og:site_name" content="kinderkiez.net" />
            {!!configId && (
              <>
                <meta
                  property="og:image"
                  content={`https://app.kinderkiez.net/configurations/${configId}.jpg?alt=media`}
                />
                <meta
                  property="og:image:width"
                  content={`${Math.floor(FORMATS[format].width * imageScale)}`}
                />
                <meta
                  property="og:image:height"
                  content={`${Math.floor(FORMATS[format].height * imageScale)}`}
                />
              </>
            )}
          </Head>
          <>
            <Layout
              header={
                <Header
                  // onDeluxeClick={() => setShowDeluxeModal(true)}
                  onDeluxeClick={() => {}}
                  canSave
                  onSave={onSave}
                />
              }
              sidebar={
                <NoSSR>
                  {!setupLoading && (
                    <UI
                      state={step}
                      activeStep={activeStep}
                      format={format}
                      prefill={prefill}
                      dirty={isDirty}
                      overrides={overrides}
                      onStateChange={onStateChange}
                      onLocationChange={onLocationChange}
                      onFormatChange={onFormatChange}
                      onPrefillChange={onPrefillChange}
                      onFileSelect={onFileSelect}
                      onFeatureSearch={onFeatureSearch}
                      onCategorySelect={onCategorySelect}
                      onStreetLabelSpacingUpdate={(spacing) => {
                        resetConfig();
                        dispatchMapStyle({
                          type: "SET_STREET_LABEL_SPACING",
                          spacing,
                        });
                      }}
                      onBackgroundColorUpdate={onBackgroundColorUpdate}
                      onPathVisibilityUpdate={(hidden) => {
                        resetConfig();
                        dispatchMapStyle({
                          type: "SET_PATHS_HIDDEN",
                          hidden,
                        });
                      }}
                      onContinueEdit={onContinueEdit}
                      onReset={onReset}
                      onSave={onSave}
                      onSubmit={() => setShowOrderModal(true)}
                    />
                  )}
                </NoSSR>
              }
            >
              <NoSSR>
                {!setupLoading && (
                  <ScaleContainer
                    scale={scale}
                    onScaleUpdate={setScale}
                    padding={isMobile ? 16 : 60}
                  >
                    <Map
                      ref={mapRef}
                      viewport={viewport}
                      state={step as MapState}
                      scale={scale}
                      format={format}
                      prefill={prefill}
                      render={showOrderModal}
                      onViewportChange={setViewport}
                      onRender={setPreviewImage}
                      onFeatureAdded={resetConfig}
                      onFeatureUpdated={resetConfig}
                      onFeaturePlacement={resetConfig}
                    />
                  </ScaleContainer>
                )}
              </NoSSR>
            </Layout>
            <SavingModal
              show={showModal}
              saving={saving}
              configId={configId}
              onClose={() => {
                setShowModal(false);
                setSaving(false);
              }}
              onConfirmExisting={() => {
                populateMap(existingConfig, false);
                setShowModal(false);
              }}
              onDenyExisting={() => {
                try {
                  localStorage.removeItem(BACKUP_KEY);
                  setConfigId(null);
                  setShowModal(false);
                } catch (e) {}
              }}
            />
            <OrderModal
              show={showOrderModal}
              format={format}
              loading={loading}
              previewImage={previewImage}
              onPurchase={onPurchase}
              onCancel={onOrderCancel}
            />
            {/* <DeluxeModal
              show={showDeluxeModal}
              onClose={() => setShowDeluxeModal(false)}
            /> */}
          </>
          {!!errorMessage && (
            <ErrorBar
              message={errorMessage}
              onClose={() => setErrorMessage("")}
            />
          )}
          <NoSSR>
            {isMobile && !setupLoading && step !== "PREVIEW" && <MobileModal />}
          </NoSSR>
        </MapStyleContext.Provider>
      </StylesProvider>
    </MuiThemeProvider>
  );
};

export default MapPage;
