import { Feature, Point } from "geojson";

export const MAX_ICON_SIZE = 6;
export const MIN_ICON_SIZE = 0.8;

export function activeIconReducer(
  state = {} as Feature<Point>,
  action
): Feature<Point> {
  const NOW = new Date().getTime();

  switch (action.type) {
    case "SET":
      return action.feature;
    case "RESET":
      return null;
    case "MIRROR":
      return {
        ...state,
        properties: {
          ...state.properties,
          icon: state.properties.icon.match(/_mirrored$/)
            ? state.properties.icon.replace(/_mirrored$/, "")
            : state.properties.icon + "_mirrored"
        }
      };
    case "OFFSET":
      return {
        ...state,
        properties: {
          ...state.properties,
          offset: [
            action.coordinates[0] - state.geometry.coordinates[0],
            action.coordinates[1] - state.geometry.coordinates[1]
          ]
        }
      };
    case "POSITION":
      return {
        ...state,
        geometry: {
          ...state.geometry,
          coordinates: [
            !action.ignoreOffset && state.properties.offset
              ? action.position[0] - state.properties.offset[0]
              : action.position[0],
            !action.ignoreOffset && state.properties.offset
              ? action.position[1] - state.properties.offset[1]
              : action.position[1]
          ]
        },
        properties: {
          ...state.properties
        }
      };
    case "ROTATE_LEFT":
      return {
        ...state,
        properties: {
          ...state.properties,
          rotation: (state.properties.rotation || 0) - 5
        }
      };
    case "ROTATE_RIGHT":
      return {
        ...state,
        properties: {
          ...state.properties,
          rotation: (state.properties.rotation || 0) + 5
        }
      };
    case "INCREASE_SIZE":
      return {
        ...state,
        properties: {
          ...state.properties,
          size: Math.min(MAX_ICON_SIZE, (state.properties.size || 1) + 0.1)
        }
      };
    case "DECREASE_SIZE":
      return {
        ...state,
        properties: {
          ...state.properties,
          size: Math.max(MIN_ICON_SIZE, (state.properties.size || 1) - 0.1)
        }
      };
    default:
      return state;
  }
}
