import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FORMATS, Format } from "../constants/formats";
import { FormatButton } from "./FormatButton";
import styles from "./FormatSelector.css";
import { Button } from "./Button";

const CARPET_TAB = 0;
const POSTER_TAB = 1;

const carpets = (formats: Record<string, Format>) =>
  Object.entries(formats).filter(
    ([name]) => !name.startsWith("PANORAMA") && !name.startsWith("CLOSEUP")
  );
const panoramas = (formats: Record<string, Format>) =>
  Object.entries(formats).filter(([name]) => name.startsWith("PANORAMA"));
const closeup = (formats: Record<string, Format>) =>
  Object.entries(formats).filter(([name]) => name.startsWith("CLOSEUP"));

export const FormatSelector = ({ selected, onSelect }) => {
  const { t } = useTranslation();
  // const [currentTab, setCurrentTab] = useState(CARPET_TAB);

  // useEffect(() => {
  //   if (
  //     (currentTab === CARPET_TAB &&
  //       panoramas(FORMATS).some(([name]) => name === selected)) ||
  //     closeup(FORMATS).some(([name]) => name === selected)
  //   ) {
  //     setCurrentTab(POSTER_TAB);
  //   }
  // }, [selected]);

  return (
    <>
      {/* <div className={styles.container}>
        <div className={styles.button}>
          <Button
            expanded
            tertiary={currentTab !== CARPET_TAB}
            onClick={() => setCurrentTab(CARPET_TAB)}
          >
            {t("poster_test--carpet")}
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            expanded
            tertiary={currentTab !== POSTER_TAB}
            onClick={() => setCurrentTab(POSTER_TAB)}
          >
            {t("poster_test--poster")}
          </Button>
        </div>
      </div> */}

      {/* {currentTab === CARPET_TAB && ( */}
      <div className={styles.formatContainer}>
        {carpets(FORMATS).map(([name, format]) => (
          <div key={name}>
            <FormatButton
              id={name}
              format={format}
              selected={selected === name}
              onSelect={onSelect}
            />
          </div>
        ))}
      </div>
      {/* )} */}
      {/* {currentTab === POSTER_TAB && (
        <>
          <Typography variant="body1" className={styles.title}>
            {t("poster_test--panorama")}
          </Typography>
          <div className={styles.formatContainer}>
            {panoramas(FORMATS).map(([name, format]) => (
              <div key={name}>
                <FormatButton
                  id={name}
                  format={format}
                  selected={selected === name}
                  onSelect={onSelect}
                />
              </div>
            ))}
          </div>
          <Typography variant="body1" className={styles.title}>
            {t("poster_test--closeup")}
          </Typography>
          <div className={styles.formatContainer}>
            {closeup(FORMATS).map(([name, format]) => (
              <div key={name}>
                <FormatButton
                  id={name}
                  format={format}
                  selected={selected === name}
                  onSelect={onSelect}
                />
              </div>
            ))}
          </div>
        </>
      )} */}
    </>
  );
};
