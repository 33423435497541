import React from "react";
import { BaseControl, BaseControlProps } from "react-map-gl";
import { Map } from "mapbox-gl";

interface Props extends BaseControlProps {
  onDrop: (
    e: DragEvent,
    location: { x: number; y: number; coordinates: [number, number] },
    map: Map
  ) => void;
}

export class DragOverlay extends BaseControl<Props, HTMLDivElement> {
  _onDragOver = e => {
    e.preventDefault();
  };

  _onDrop = e => {
    const { mapContainer, viewport, map } = this._context;

    e.preventDefault();
    var rect = mapContainer.getBoundingClientRect();
    var scaleX = rect.width / mapContainer.offsetWidth;
    var scaleY = rect.height / mapContainer.offsetHeight;
    var x = (e.clientX - rect.left) / scaleX; //x position within the element.
    var y = (e.clientY - rect.top) / scaleY; //y position within the element.

    this.props.onDrop(
      e,
      { x, y, coordinates: viewport.unproject([x, y]) },
      map
    );
  };

  componentDidMount() {
    super.componentDidMount();

    const { mapContainer } = this._context;

    mapContainer.addEventListener("dragover", this._onDragOver);
    mapContainer.addEventListener("drop", this._onDrop);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    const { mapContainer } = this._context;

    mapContainer.removeEventListener("dragover", this._onDragOver);
    mapContainer.removeEventListener("drop", this._onDrop);
  }

  _render() {
    return <div ref={this._containerRef} />;
  }
}
