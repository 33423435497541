import { useTranslation } from "react-i18next";
import { FORMATS } from "../constants/formats";
import { Button } from "./Button";
import { Modal } from "./Modal";
import { RatioContainer } from "./style/RatioContainer";

export const OrderModal = ({
  show,
  loading,
  format,
  previewImage,
  onPurchase,
  onCancel,
}) => {
  const { t } = useTranslation();

  const { width, height, round } = FORMATS[format];

  return (
    <Modal
      title={t("order__confirm-title")}
      show={show}
      actions={
        <>
          <Button secondary onClick={onCancel}>
            {t("order__cancel")}
          </Button>
          <Button onClick={onPurchase} disabled={loading}>
            {loading ? t("loading") : t("order__order-now")}
          </Button>
        </>
      }
    >
      <p style={{ textAlign: "center" }}>{t("order__confirm-subtitle")}</p>
      <RatioContainer ratio={height / width}>
        <img
          style={{
            border: "4px solid #afc2cb",
            borderRadius: round ? "50%" : 0,
          }}
          src={previewImage}
          alt=""
        />
      </RatioContainer>
    </Modal>
  );
};
