import cn from "classnames";
import { FunctionComponent, HTMLProps, useState } from "react";
import { CSSTransition } from "react-transition-group";
import Arrow from "../../assets/icons/arrow-right.svg";
import styles from "./Accordion.css";

export const Accordion: FunctionComponent<
  {
    title: string;
    onToggle?: (open: boolean) => any;
    open: boolean;
    independent?: boolean;
  } & HTMLProps<HTMLDivElement>
> = ({ onToggle, title, open, children, independent, ...props }) => {
  const [openState, setOpenState] = useState(open);

  const toggleState = independent ? openState : open;

  return (
    <div {...props} className={styles.container}>
      <div
        className={cn([styles.title, toggleState && styles.titleOpen])}
        onClick={() => {
          independent && setOpenState(!openState);
          onToggle && onToggle(!openState);
        }}
      >
        <img
          className={cn([styles.titleIcon])}
          width="16"
          height="16"
          src={Arrow}
          alt=""
        />{" "}
        {title}
      </div>
      <CSSTransition
        in={toggleState}
        timeout={200}
        unmountOnExit
        onExit={node => (node.style.height = node.clientHeight + "px")}
        classNames={{
          enter: styles.accordionEnter,
          enterActive: styles.accordionEnterActive,
          exit: styles.accordionExit,
          exitActive: styles.accordionExitActive
        }}
      >
        <div className={styles.content}>{children}</div>
      </CSSTransition>
    </div>
  );
};
