import cn from "classnames";
import { FunctionComponent, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popup } from "react-map-gl";
import Repeatable from "react-repeatable";
import checkIcon from "../assets/icons/check.svg";
import rotateLeftIcon from "../assets/icons/rotate_left.svg";
import rotateRightIcon from "../assets/icons/rotate_right.svg";
import trashIcon from "../assets/icons/trash.svg";
import zoomInIcon from "../assets/icons/zoom_in.svg";
import zoomOutIcon from "../assets/icons/zoom_out.svg";
import mirrorIcon from "../assets/icons/mirror.svg";
import styles from "./IconControls.css";

interface Props {
  longitude: number;
  latitude: number;
  scale: number;
  size: number;
  onScaleUp: () => any;
  onScaleDown: () => any;
  onRotateLeft: () => any;
  onRotateRight: () => any;
  onMirror: () => any;
  onDelete: () => any;
  onClose: () => any;
}

export const IconControls: FunctionComponent<Props> = ({
  longitude,
  latitude,
  scale,
  size,
  onScaleUp,
  onScaleDown,
  onRotateLeft,
  onRotateRight,
  onMirror,
  onDelete,
  onClose
}) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>();
  const [contentSize, setContentSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentSize({
        width: contentRef.current.clientWidth * (1 / scale),
        height: contentRef.current.clientHeight * (1 / scale)
      });
    }
  }, [contentRef, scale]);

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      closeButton={false}
      anchor="top"
      offsetTop={10}
      className={styles.popup}
      tipSize={8 * (1 / scale)}
    >
      <div style={{ ...contentSize, position: "relative" }}>
        <div
          className={cn([
            "mapboxgl-ctrl",
            "mapboxgl-ctrl-group",
            styles.container
          ])}
          ref={contentRef}
          style={{ position: "absolute", transform: `scale(${1 / scale})` }}
        >
          <Repeatable
            tag="button"
            type="button"
            className={styles.control}
            onPress={onRotateLeft}
            onHold={onRotateLeft}
            title={t("controls_rotate-left")}
          >
            <img
              className={styles.icon}
              src={rotateLeftIcon}
              alt={t("controls_rotate-left")}
            />
          </Repeatable>
          <Repeatable
            tag="button"
            type="button"
            className={styles.control}
            onPress={onRotateRight}
            onHold={onRotateRight}
            title={t("controls_rotate-right")}
          >
            <img
              className={styles.icon}
              src={rotateRightIcon}
              alt={t("controls_rotate-right")}
            />
          </Repeatable>
          <Repeatable
            tag="button"
            type="button"
            className={styles.control}
            //disabled={size >= MAX_ICON_SIZE}
            onPress={onScaleUp}
            onHold={onScaleUp}
            title={t("controls_scale-up")}
          >
            <img
              className={styles.icon}
              src={zoomInIcon}
              alt={t("controls_scale-up")}
            />
          </Repeatable>
          <Repeatable
            tag="button"
            type="button"
            className={styles.control}
            //disabled={size <= MIN_ICON_SIZE}
            onPress={onScaleDown}
            onHold={onScaleDown}
            title={t("controls_scale-down")}
          >
            <img
              className={styles.icon}
              src={zoomOutIcon}
              alt={t("controls_scale-down")}
            />
          </Repeatable>
          <button
            className={styles.control}
            onClick={onMirror}
            title={t("controls_mirror")}
          >
            <img
              className={styles.icon}
              src={mirrorIcon}
              alt={t("controls_mirror")}
            />
          </button>
          <button
            className={styles.control}
            onClick={onDelete}
            title={t("controls_delete")}
          >
            <img
              className={styles.icon}
              src={trashIcon}
              alt={t("controls_delete")}
            />
          </button>
          <button
            className={styles.control}
            onClick={onClose}
            title={t("controls_close")}
          >
            <img
              className={styles.icon}
              src={checkIcon}
              alt={t("controls_close")}
            />
          </button>
        </div>
      </div>
    </Popup>
  );
};
